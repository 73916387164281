@import "./_BB8Variables";
@import "../vendor/airmiles/pattern-library/sass/base/colors";
@import "./_BB8Variables";

.bb8-nav-bar-wrapper {
  margin: 0 auto;
  background-size: contain;
  height: 96px;
  box-shadow: none;
  h1 {
    color: color("white");
    font-size: 40px;
  }
  margin-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.bb8-nav-bar-wrapper-prod {
  background-image: none;
  background-color: $bb8-header-prod-background-color;
}

.bb8-nav-bar {
  font-family: "Omnes", Calibri, "Trebuchet MS";

  @extend .bb8-basic-flex;
  width: 1366px;
  @media screen and (max-width: 1365px) {
    width: 100%;
  }
  // margin: 0 auto;

  * {
    flex-grow: 1;
  }

  .bb8-title-wapper {
    @extend .bb8-basic-flex;

    * {
      margin-right: 1em;
    }

    h1 {
      font-weight: lighter;
    }
  }
}
