@import "./shared";

.hide {
  display: none;
}

.bar {
  fill: #bbbdbf ;
  &:hover {
    fill: #0a6fb3;
  }
  &.current {
    fill: #3a84b2;
  }
}

.data-line {
  fill: none;
  stroke: #d963a4;
  stroke-width: 4px;
}

.data-circle,
.data-diamond {
	fill: #d963a4;
}

.line-and-dots__text {
  fill: #d30e8b;
}

#table-chart-list {
  width: 100%;
  table-layout: fixed;
  th:nth-child(1) {
    width: 33%;
  }
  th:nth-child(2) {
    width: 33%;
  }
  th:nth-child(3) {
    width: 34%;
  }
  th, td {
    text-align: center;
  }
}
