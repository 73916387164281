$bb8-primary-color: #0a6fb3;
$bb8-bg-color: #ffffff;
$bb8-screen-size-default: 1366px;
$bb8-shadow-color-primary: #dddddd;
$bb8-border-radius: 3px;
$bb8-header-prod-background-color: #51253d;

.bb8-basic-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
