@import "./shared";

.logo-wrapper {
  height: 5em;
  width: 5em;
  vertical-align: middle;
  margin: 0.2em;
}

.logo-text {
  line-height: 5.2em;
  // @extend %ellipses-overflow;
}

.title-wrapper {
  padding-top: 1em;
}

.chip {
  margin: 0.3em;
}
