@import "./_BB8Variables";
@import "../vendor/airmiles/pattern-library/sass/base/_colors.scss";

.bb8-search-wrapper {
  position: relative;
  min-height: 3em;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  .bb8-search {
    background: color("grey-accent-light-4");
    color: color("black");
    flex: 1 1 100%;
    font-family: "Omnes";
    font-size: 16px;
    border-radius: $bb8-border-radius;
    border: 0;
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;
    width: 100%;

    &:focus {
      outline: color("blue-accent") solid 3px;
      outline-offset: -3px;
    }
    &.dirty.invalid {
      background: color("error-light");
    }
    &.valid {
      background: color("grey-light-4");
    }
  }
  .bb8-search-icon {
    position: absolute;
    right: 0rem;
    visibility: hidden;
    pointer-events: none;
    &.show {
      visibility: visible;
      pointer-events: all;
    }
  }

  // @TODO: try to remove the !important.
  // I used them to fulfill the style-guide required for RGA-162
  // but BB8Lookup should be width responsive.
  // .bb8-offer-modal-style {
  //   background: none !important;
  //   font: inherit !important;
  //   color: rgba(0, 0, 0, 0.54) !important;
  //   width: 220px !important;
  //   border: 1px solid gray !important;
  //   margin-top: 21px !important;
  //   padding: 27px 12px !important;
  // }
  // .bb8-customer-modal-style {
  //   background: none !important;
  //   font: inherit !important;
  //   color: rgba(0, 0, 0, 0.54) !important;
  //   width: 450px !important;
  //   border: 1px solid gray !important;
  //   margin-top: 21px !important;
  //   padding: 27px 12px !important;
  // }
}

.margin-20-top {
  margin-top: 20px !important;
}

.rem-1-font {
  font-size: 1rem !important;
}
