@import "./shared";

.search-field-row {
  padding-bottom: 4em;
  padding-top: 3em;
}

.btn-add-group {
  height: 3.2em;
}

.btn-clear-search {
  border-radius: 50%;
}

.bb8-search-wrapper {
  > div {
    width: 100%;
  }
}

#table-group-list {
  width: 100%;
  table-layout: fixed;
  tr th:nth-child(2) {
    width: 70%;
  }
}

.partner-list {
  width: 95%;
  @extend %ellipses-overflow;
}
