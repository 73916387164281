.custom-tab-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 5em 40em;
  grid-template-areas: ". search-group-name" "table-tab-content table-tab-content";
}

#search-group-name {
  grid-area: search-group-name;
}

#table-tab-content {
  grid-area: table-tab-content;
  width: 100%;
  table-layout: fixed;
  // Second column in the table
  tr th:nth-child(2) {
    width: 60%;
  }
}

#table-tab-content-users {
  grid-area: table-tab-content;
  width: 100%;
  table-layout: fixed;
  // First column in the table
  tr th:nth-child(1) {
    width: 25%;
  }
  tr th:nth-child(2) {
    width: 35%;
  }
}

.lookup-partner {
  padding-bottom: 2em;
}
