/*------------------------------------*\
   Modal
\*------------------------------------*/

/*
 * Full page Modal takeover
 *
 * Modal Structure
 * Responsive
 */

// ---------- Modal Structure ---------

.am-modal {
  display: flex;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: zindex("high");
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: rgba(color("black"), 0.9);
}

.am-modal--visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s, visibility 0.5s;

  .am-modal__content {
    opacity: 1;
    transition: opacity 0.5s 0.5s;
  }
}

.am-modal--hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s 0.5s, visibility 0.5s 1s;
}

.am-modal__close-container {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.am-modal__content {
  opacity: 0;
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: color("white");
  transition: opacity 0.5s;
}

.am-modal__close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.am-modal__heading {
  width: 100%;
  padding: 30px 20px 0;
  font-weight: 300;
  color: color("blue");
}

.am-modal__subheading {
  display: none;
}

.am-modal__body {
  margin: 20px;
}

.am-modal__journey {
  position: relative;
  margin: 20px 0 0;
  padding: 20px 0;
  background: linear-gradient(
    to bottom,
    color("grey-accent-light-4"),
    color("white") 50px
  );

  &-body {
    padding: 0 20px;
  }
}

.am-modal__button-container {
  height: 50px;
  margin: 20px 20px 0;
}

.am-modal__left-button-label-mobile {
  display: block;
}

.am-modal__left-button-label-desktop {
  display: none;
}

.am-modal__column-container {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px;
  white-space: nowrap;
}

.am-modal__column {
  width: 100%;
  margin: 20px 0 0;
  white-space: normal;
}

// ---------- Responsive ---------

@include media-query("md") {
  .am-modal__content {
    width: 825px;
  }

  .am-modal__heading {
    padding: 45px 50px 0;
  }

  .am-modal__subheading {
    display: block;
    margin: 35px 50px 0;
    font-weight: 400;
    color: color("grey-dark");
  }

  .am-modal__body {
    margin: 35px 50px 50px;
  }

  .am-modal__journey {
    margin: 40px 0 0;
    padding: 40px 0 50px;

    &-body {
      width: 100%;
      padding: 0 50px;
    }
  }

  .am-modal__button-container {
    height: 50px;
    margin: 35px 50px 0;
  }

  .am-modal__left-button-label-mobile {
    display: none;
  }

  .am-modal__left-button-label-desktop {
    display: block;
  }

  .am-modal__column-container {
    width: 100%;
    flex-direction: row;
    padding: 0 50px;
  }

  .am-modal__column {
    width: calc(50% - 20px);
    margin: 0;
  }
}
