.grid-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-rows: 2em auto;
  grid-template-areas: "entity-name entity-name" "entity-details tab-container";
  grid-gap: 2em;
  margin-top: 1em;
}

.tab-container {
  grid-area: tab-container;
}
.entity-name {
  grid-area: entity-name;
  align-items: center;
}

.entity-details {
  grid-area: entity-details;
}
