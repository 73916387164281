@import "./vendor/airmiles/pattern-library/sass/style.scss";

html,
body,
#root {
  height: 100%;
}
@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 10mm 5mm 10mm 5mm;
  }
  body,
  section {
    page-break-after: always;
  }
  .offer-details-list-row {
    max-width: 1000px !important;
  }
  .offer-block-container {
    max-width: 1100px;
  }
  .bb8-nav-bar-wrapper,
  .navbar-links-wrapper,
  .offer-button-1,
  .offer-button-2,
  #offer-details-list-header,
  #offer-details-list-back {
    display: none !important;
  }
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
}
}