$error-red: #f9e8f1;

%ellipses-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.error-message {
  background-color: $error-red;
  border-radius: 0.2em;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0.1em 0.2em -0.05em,
    rgba(0, 0, 0, 0.14) 0 0.3em 0.5em 0, rgba(0, 0, 0, 0.12) 0 0.01em 1em 0;
  margin-bottom: 1em;
  margin-top: -3em;
  padding: 1em;
}

.tooltip-closer {
  line-height: 1.5em;
}

.line-height-spread-md {
  line-height: 1.75em;
}

.text-color-white {
  color: white;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.mb-1 {
  margin-bottom: 1em;
}